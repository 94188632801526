// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-bilder-js": () => import("./../src/pages/bilder.js" /* webpackChunkName: "component---src-pages-bilder-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-callback-js": () => import("./../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-medlem-js": () => import("./../src/pages/medlem.js" /* webpackChunkName: "component---src-pages-medlem-js" */),
  "component---src-pages-om-js": () => import("./../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-styrelse-js": () => import("./../src/pages/styrelse.js" /* webpackChunkName: "component---src-pages-styrelse-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

